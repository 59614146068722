import Image from "next/image"

import { Links } from "config/links"

import { Box } from "components/shared/box"
import { ClippedBox } from "components/shared/clipped-box/clipped-box"
import { ClippedCard } from "components/shared/landing/clipped-card"
import { Link } from "components/shared/link"
import { Text } from "components/shared/text"

import clippedCorners from "utils/clipped-corners"

import stakeImg from "/public/images/pages/index/stake-section/stake.webp"

export function StakeSection() {
  return (
    <ClippedCard full className="flex-col lg:justify-between lg:flex-row shadow-card-point">
      <Box className="flex-1 justify-center text-left lg:mr-4 text-white lg:mt-0">
        <Text as="h2">Stake to Earn</Text>
        <p className="flex flex-col text-base text-white opacity-80 mt-4 lg:mt-6 lg:mr-8">
          <span>BITS token holders can stake their tokens to receive more $BITS!</span>
          <Link href={Links.VOTE_LOCK} className="mt-6">
            <span className="underline cursor-pointer">Explore BITS economy and Vote Locking</span>
          </Link>
        </p>
      </Box>
      <Box className="flex-1 w-full lg:w-auto mt-6 lg:mt-0">
        <Box className="flex-1 w-full lg:w-auto">
          <ClippedBox
            corners={clippedCorners.lb_rt}
            className="relative ml-auto rounded-lg w-full lg:w-[25rem] h-[15rem] overflow-hidden self-end shadow-md"
          >
            <Image src={stakeImg} quality={100} objectFit="cover" layout="fill" alt="battle image" />
          </ClippedBox>
        </Box>
      </Box>
    </ClippedCard>
  )
}
