import { useRouter } from "next/router"
import { useEffect } from "react"
import { useCookies } from "react-cookie"

import { AddictiveSection } from "components/pages/index/addictive-section"
import { BattleSection } from "components/pages/index/battle-section"
import { DescriptionSection } from "components/pages/index/description-section"
import { EconomySection } from "components/pages/index/economy-section"
import { FightToEarnSection } from "components/pages/index/fight-to-earn-section"
import { NumbersSection } from "components/pages/index/numbers-section"
import { ProduceSection } from "components/pages/index/produce-section"
import { RoadmapSection } from "components/pages/index/roadmap-section"
import { StakeSection } from "components/pages/index/stake-section"
import { TopSection } from "components/pages/index/top-section"
import { INVITE_COOKIE_NAME } from "components/pages/index/utils"
import { PartnersSection } from "components/sections/partners-section"
import { BackgroundImage } from "components/shared/background-img"
import { Box } from "components/shared/box"
import { Section } from "components/shared/landing/section"
import { SectionSpace } from "components/shared/landing/section-space"

import { UTM_COOKIE_NAME } from "utils/utm"

// import { PrizeBanner } from "components/shared/prize-banner"

const ARROW_SCROLL_TO = "second-section"

export default function Home() {
  const router = useRouter()
  const [, setCookie] = useCookies()

  useEffect(() => {
    if (router.query.inviting_user) {
      setCookie(INVITE_COOKIE_NAME, router.query.inviting_user, {
        path: "/",
        maxAge: 24 * 60 * 60 * 10,
      })
    }
  }, [router.query.inviting_user, setCookie])

  useEffect(() => {
    if (router.query) {
      setCookie(UTM_COOKIE_NAME, new URLSearchParams(router.query as Record<string, string>).toString(), {
        path: "/",
        maxAge: 24 * 60 * 60 * 10,
      })
    }
  }, [router.query, setCookie])

  return (
    <Box className="flex-1">
      {/* <PrizeBanner /> */}
      <TopSection scrollTo={ARROW_SCROLL_TO} />
      <div id={ARROW_SCROLL_TO} />
      <DescriptionSection />
      <NumbersSection />
      <BackgroundImage
        imageSrc="/images/pages/index/bg-play-to-earn.png"
        hideMobile
        className="bg-auto md:bg-repeat-x md:bg-cover mt-10"
      >
        <Section
          className="items-center mb-0 md:mb-0 text-center"
          header={
            <>
              <span className="text-primary">Gameplay</span> Designed to Earn
            </>
          }
        >
          <Box className="gap-9">
            <FightToEarnSection />
            <ProduceSection />
            <BattleSection />
            <EconomySection />
            <StakeSection />
          </Box>
        </Section>
      </BackgroundImage>
      <SectionSpace />

      <AddictiveSection />
      <SectionSpace />

      <RoadmapSection />
      <SectionSpace />

      <PartnersSection />
    </Box>
  )
}
